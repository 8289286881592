import React from "react"
import Layout from "../components/layout"


export default () => (
  <Layout>

    <h3>The100.io Mobile App</h3>
    <p><small>
      <b>Senior Software Engineer, Mobile</b> | React Native | iOS | Android
    <br />
      <a href="https://itunes.apple.com/us/app/the100-io-group-matchmaking/id972830288?mt=8">View in iOS App Store</a> | <a href="https://play.google.com/store/apps/details?id=io.the100.mobile&hl=en_US">View in Google Play Store</a> | <a href="https://github.com/mlapeter/the100reactapp">View on Github</a>
    </small></p>

    <p>Primary developer and responsible for all aspects of building, maintaining, and deploying app across both major App Stores. 4.7 star rating from 250+ reviews.</p>
    <p>The100.io mobile app lets video game players find and group up with likeminded players. They can communicate using realtime chat and private messages with push and email notifications, schedule games and automatically notify their group, and browse a customized social feed to keep up with their group's activity. Project is open source and <a href="https://github.com/mlapeter/the100reactapp">viewable on Github</a>.</p>

    <h3>The100.io Web App</h3>
    <p>
      <small>
        <b>Senior Software Engineer, Full Stack</b> | Ruby on Rails | React | S3 | Heroku | Firebase | Stripe
        <br />
        <a href="https://www.the100.io">View Website</a> | <a href="https://www.the100.io/api-docs/index.html">View API</a>
      </small>
    </p>
    <p>Primary developer and responsible for building and maintaining front end, back end, and API powering mobile app, along with managing volunteer developers and content creators.</p>
    <p><a href="https://www.the100.io">The100.io</a> lets video game players find and group up with likeminded players. The site is a fully built out social network that handles over 30,000 Monthly Active Users in over 3,000 groups generating 250,000+ notifications per month, with past spikes of traffic as high as 500 concurrent users.</p><p> Features include automatic matchmaking into a group based off a user's criteria, realtime chat, in-browser and email notifications, private messaging, friending/ unfriending/ blocking other users, creating, scheduling, and searching upcoming gaming sessions, creating groups, and a custom built Patreon-style supporter program handling payments through Stripe.</p>
    <h3>LimbSnap Mobile App</h3>
    <p>
      <small>
        <b>Senior Software Engineer, Mobile</b> | React Native | iOS | Android | Stripe | S3
        <br />
        <a href="https://itunes.apple.com/us/app/limb-snap/id1373326595">View in iOS App Store</a> | <a href="https://play.google.com/store/apps/details?id=com.limbsnap.limbsnapapp">View in Google Play Store</a> | <a href="https://limbsnap.com/">View Website</a> </small>
    </p>
    <p>Primary mobile developer and responsible for all aspects of building, maintaining, and deploying app across both major App Stores. Currently has perfect 5 star rating.</p>
    <p>Limbsnap lets Arborists take a picture of trees that need work and generate and mail a marketing postcard on the fly. It automatically grabs the closest addresses, generates a customized sales message, formats the postcard, and posts the data to a postcard printing company. Users can view and search sent postcards, check printing status, and make purchases directly through the app using stripe.</p>

    <h3>Discord Chatbot</h3>
    <p>
      <small>
        Node.js | Discord API | Heroku
        <br />
        <a href="https://github.com/mlapeter/the100bot">View on Github</a> </small>
    </p>
    <p>Built an open source Discord Chatbot that connects to The100.io. Users can view and join group gaming sessions and create gaming sessions directly from Discord chat. Currently used by 750+ Discord servers.</p>

    <h3>Node.js Twitter Bot</h3>
    <p>
      <small>
        Node.js | Twitter API | Heroku
        <br />
        Private Repository </small>
    </p>
    <p>Built a Twitter Bot in Node.js. It automatically retweets popular tweets from specified accounts, favorites tweets, and follows users who fit basic criteria. Currently generating 5-7 new followers per day.</p>

    <h3>Mobile App With Image Recognition</h3>
    <p>
      <small>
        React Native | Google Cloud Vision API | Firebase
        <br />
        <a href="https://expo.io/@mlapeter/google-cloud-vision">View Demo</a> | <a href="https://medium.com/@mlapeter/using-google-cloud-vision-with-expo-and-react-native-7d18991da1dd">View Tutorial</a> | <a href="https://github.com/mlapeter/google-cloud-vision">View on Github</a> </small>
    </p>
    <p>Built a simple demo app using Google's Cloud Vision API. You can take a picture, and it will return labels of objects in the image such as "cat", along with things like detecting faces and even a person's emotional state based off their face. Images are saved to firebase, then posted to the Cloud Vision API which does the processing.</p>

  </Layout>
)